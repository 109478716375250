export enum BlessingTypeId { // TypeDefIndex: 9533
  Necromancy = 1101,
  TimeSlowdown = 1102,
  ToxicBlade = 1201,
  Exterminator = 1202,
  MagicOrb = 1301,
  Fearless = 1302,
  LightOrbs = 2101,
  LeadershipDomination = 2102,
  EnhancedWeapon = 2201,
  Vanguard = 2202,
  AdvancedHeal = 2301,
  Courage = 2302,
  Execute = 3101,
  SoulDrinker = 3102,
  ChainBreaker = 3201,
  AdvancedLeadership = 3202,
  Adaptation = 3301,
  Amplification = 3302,
  Meteor = 4101,
  Polymorph = 4102,
  MagicFlame = 4201,
  Penetrator = 4202,
  Agility = 4301,
  Carapace = 4302,
}
