import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ImmunitySvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 34.5 39.75">
    <path d="m33.07,14.37c0,3.57-.01,7.14,0,10.71,0,.98-.22,1.88-.63,2.75-.54,1.14-1.25,2.16-2.06,3.12-1.18,1.39-2.52,2.6-4.03,3.6-1.05.69-2.2,1.22-3.32,1.78-.8.39-1.64.72-2.47,1.05-.75.29-1.5.57-2.27.81-.25.08-.56.06-.81-.02-1.6-.52-3.21-1.01-4.73-1.74-1.94-.92-3.75-2.07-5.4-3.47-.74-.63-1.45-1.3-2.05-2.07-1-1.27-1.86-2.64-2.45-4.16-.18-.47-.25-1-.27-1.51-.05-1.14-.04-2.28-.04-3.42,0-2.48,0-4.95,0-7.43,0-3.35-.01-6.7-.01-10.05,0-.54.03-1.08.04-1.62,0-.28.15-.48.36-.62.07-.05.23-.04.32,0,.28.14.55.3.81.47.71.48,1.35,1.04,2.13,1.41,1.42.68,2.88,1.14,4.47.93.67-.09,1.36-.12,2.01-.29,1.01-.26,1.92-.76,2.74-1.41.54-.42,1.05-.87,1.57-1.33.46-.41,1.07-.44,1.54-.05.26.21.5.43.75.66.73.67,1.56,1.2,2.45,1.59,1.14.49,2.31.93,3.57.9,1.07-.02,2.14-.06,3.16-.46,1.09-.42,2.1-.99,3.01-1.75.27-.23.58-.44.9-.59.34-.16.63.05.65.44.02.34.04.69.04,1.03,0,3.58,0,7.16,0,10.73Zm-22.93,2.12s0,.01,0,.02c-.61,0-1.22,0-1.83,0-.59,0-1.18.02-1.77.03-.29,0-.35.07-.36.36,0,.32,0,.64,0,.96,0,1.61,0,3.22.01,4.82,0,.34.22.51.55.49.8-.05,1.6-.13,2.4-.15,1.04-.03,2.08-.01,3.12,0,.55,0,1.1.02,1.65.05.29.01.36.09.38.38.01.23.01.45,0,.68-.04,2.11-.09,4.21-.13,6.32-.01.57.33.9.89.86.55-.04,1.1-.1,1.65-.1.98,0,1.95.03,2.93.06.25,0,.5.08.75.08.54,0,.79-.28.78-.83-.01-.39-.05-.78-.06-1.17-.04-1.99-.07-3.98-.12-5.97,0-.25.1-.31.29-.34.06,0,.12,0,.18,0,1.14,0,2.28-.02,3.42,0,1.11.02,2.23.09,3.34.13.15,0,.31.05.45.03.09,0,.19-.08.26-.15.23-.26.19-.58.19-.89,0-1.54,0-3.08-.02-4.61,0-.22-.01-.44-.03-.65-.02-.24-.16-.34-.38-.35-.33-.02-.66-.04-.98-.04-2.06,0-4.13,0-6.19-.01-.47,0-.58-.13-.58-.62,0-.08,0-.16,0-.23.03-.42.08-.84.07-1.26,0-1.62-.02-3.23-.04-4.85,0-.25-.05-.51-.11-.76-.11-.46-.15-.48-.61-.48-1.8-.01-3.59-.02-5.39-.04-.49,0-.61.11-.65.6,0,.04,0,.08,0,.12.01,1.24.03,2.48.05,3.72.02,1.08.04,2.15.06,3.23,0,.37-.07.47-.42.54-.14.03-.29.03-.43.03-1.11,0-2.22,0-3.33,0Z" />
  </svg>
);

export const ImmunityIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={ImmunitySvg} {...props} />;
