export enum AreaId {
  unknown = 'unknown',
  story = 'story',
  dungeon = 'dungeon',
  arena = 'arena',
  allianceboss = 'allianceboss',
  fractions = 'fractions',
  arena3x3 = 'arena3x3',
  doomtower = 'doomtower',
  hydra = 'hydra',
}
