import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const BootSvg = () => (
  <svg width="1em" height="1em" strokeWidth={3} stroke="currentColor" fill="transparent" viewBox="0 0 27.08 29.96">
    <path d="m12.91,16.03c2.36-1.14-.38-2.55.37-4.06.68-1.38.21-3.3.49-4.93.25-1.44.83-2.82,1.31-4.38-1.55-.51-3.26-1.13-5-1.61-1.07-.3-5.74.76-7.09,1.45-1.69.86-2.46,1.87-1.71,3.24,1.38,2.52.93,5.41,2.07,7.96.33.73-.14,1.83-.29,2.76-.09.56-.12,1.2-.41,1.66-.54.86,1.06,1.19.38,2.14-.36.5-.24,1.35-.36,2.04-.14.79-.48,1.6-.42,2.37.08,1.1.56,2.62,1.5,2.7,1.64.14,3.14,1.86,4.98.4,1.37-1.08,2.66.4,3.93.68,3.09.68,7.9.8,10.4-.34,1.02-.47,2.05-.92,3.11-1.39-.61-1.25-1.13-2.34-1.75-3.61-.71,0-1.55.05-2.38,0-1.83-.13-3.33-.62-4.52-2.32-1.23-1.75-3-3.12-4.62-4.74Z" />
  </svg>
);

export const BootIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={BootSvg} {...props} />;
