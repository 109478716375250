export enum ExpressionBuilderVariable {
  HP = 1,
  HP_PERC = 2,
  ATK = 3,
  DEF = 4,
  SPD = 5,
  RES = 6,
  ACC = 7,
  CRR = 8,
  CRD = 9,
  TRG_HP = 10,
  TRG_HP_PERC = 11,
  TRG_ATK = 12,
  TRG_DEF = 13,
  TRG_SPD = 14,
  TRG_RES = 15,
  TRG_ACC = 16,
  TRG_CRR = 17,
  TRG_CRD = 18,
  TRG_DSTR_HP = 168,
  REL_TRG_HP = -1,
  REL_TRG_HP_PERC = -2,
  REL_TRG_ATK = -3,
  REL_TRG_DEF = -4,
  REL_TRG_SPD = -5,
  REL_TRG_RES = -6,
  REL_TRG_ACC = -7,
  REL_TRG_CRR = -8,
  REL_TRG_CRD = -9,
  B_HP = 19,
  B_ATK = 20,
  B_DEF = 21,
  B_SPD = 22,
  B_RES = 23,
  B_ACC = 24,
  B_CRR = 25,
  B_CRD = 26,
  TRG_B_HP = 27,
  TRG_B_ATK = 28,
  TRG_B_DEF = 29,
  TRG_B_SPD = 30,
  TRG_B_RES = 31,
  TRG_B_ACC = 32,
  TRG_B_CRR = 33,
  TRG_B_CRD = 34,
  REL_TRG_B_HP = -10,
  REL_TRG_B_ATK = -11,
  REL_TRG_B_DEF = -12,
  REL_TRG_B_SPD = -13,
  REL_TRG_B_RES = -14,
  REL_TRG_B_ACC = -15,
  REL_TRG_B_CRR = -16,
  REL_TRG_B_CRD = -17,
  CUR_HP = 35,
  TRG_CUR_HP = 36,
  REL_TRG_CUR_HP = -18,
  TRG_DSTR_HP_BY_SKILL = -170,
  destroyedHpBySkillCurrentRound = 87,
  TRG_DEBUFF_COUNT = 38,
  REL_TRG_DEBUFF_COUNT = -19,
  CUR_STAMINA = 39,
  TRG_STAMINA = 40,
  MAX_STAMINA = 41,
  REL_TRG_STAMINA = -20,
  RND = 42,
  BUFF_COUNT = 43,
  DEBUFF_COUNT = 44,
  TRG_BUFF_COUNT = 45,
  REL_TRG_BUFF_COUNT = -21,
  HERO_LEVEL = 46,
  DMG = -112,
  DMG_MUL = -22,
  DEALT_DMG = -23,
  CALCULATED_DMG = -24,
  MAX_DEALT_DMG_PERC = -25,
  EXCESSIVE_HEAL = -26,
  DEALT_HEAL = -159,
  EXCESSIVE_DAMAGE = -27,
  CHANGED_STAMINA_AMOUNT = -28,
  SKILL_REUSE_COUNT_IN_ROW = 47,
  SKILL_REUSE_COUNT_IN_ROW_CURRENT_ROUND = 48,
  SKILL_USED_COUNT = 49,
  SKILL_USED_CURRENT_TURN = 50,
  SKILL_USED_CURRENT_ROUND = 51,
  RELATION_SKILL_USED_COUNT = -29,
  EFFECT_REUSE_COUNT_IN_ROW = 52,
  EFFECT_USED_COUNT_CURRENT_TURN = 53,
  RELATED_EFFECT_REUSE_COUNT_IN_ROW = -30,
  RELATED_EFFECT_REUSE_COUNT_IN_ROW_CURRENT_ROUND = -31,
  RELATED_EFFECT_USED_COUNT_CURRENT_TURN = -32,
  EFFECT_USED_COUNT_WHOLE_BATTLE = -92,
  RELATED_EFFECT_USED_COUNT_WHOLE_BATTLE = -93,
  RELATED_EFFECT_TRIES_TO_USE_COUNT_CURRENT_TURN = -80,
  COOLDOWNABLE_SKILLS_USED_COUNT_CURRENT_ROUND = 164,
  DESTOY_HP_MULTIPLIER = -33,
  CONTINUOUS_TO_INSTANT_DMG_MUL = -34,
  BaseHealMultiplier = -134,
  CurrentHealMultiplier = -135,
  BaseChangeStaminaMultiplier = -128,
  CurrentChangeStaminaMultiplier = -136,
  KILLED_ENEMIES_WHOLE_BATTLE = 54,
  KILLED_ALLIES_WHOLE_BATTLE = 55,
  KILLED_ENEMIES_CURRENT_ROUND = 56,
  KILLED_ALLIES_CURRENT_ROUND = 57,
  OWNERS_TURN_NUMBER = 58,
  VALUE = -35,
  RARITY = 59,
  TRG_RARITY = 60,
  REL_TRG_RARITY = -36,
  REL_PRODUCER_RARITY = -37,
  SHIELDS_SUM_VALUE = 61,
  TRG_SHIELDS_SUM_VALUE = 62,
  REL_TRG_SHIELDS_SUM_VALUE = -38,
  ShieldValueOrZeroIfIgnored = 94,
  RelatedTargetShieldValueOrZeroIfIgnored = -159,
  isOwnersTurn = 63,
  isAllyTurn = 64,
  ownerIsRelatedEffectTarget = -39,
  producerIsRelatedEffectTarget = -149,
  ownerIsDead = 65,
  producerHasJustBeenKilled = -40,
  relatedEffectIsStatusDebuff = -41,
  relatedEffectIsInstantDebuff = -42,
  relatedEffectIsDebuff = -43,
  relatedEffectIsStun = -44,
  relatedEffectIsSleep = -45,
  relatedEffectIsFreeze = -46,
  relatedEffectIsProvoke = -47,
  relatedEffectIsContinuousDamage = -48,
  relatedEffectIsTimeBomb = -49,
  relatedEffectIsStatusBuff = -50,
  relatedEffectIsInstantBuff = -51,
  relatedEffectIsBuff = -52,
  relatedEffectIsBlockDamage = -53,
  relatedEffectIsUnkillable = -54,
  relatedEffectIsReviveOnDeath = -55,
  relationIsCounterattackDamage = -56,
  relationDamageIsNotHit = -57,
  relatedEffectIsGuaranteed = -58,
  isAlreadyCounterattack = 66,
  killedEnemiesCount = -59,
  killedEnemiesCountCurrentTurn = 67,
  killedEnemyMinionsCount = -60,
  killedEnemyMinionsCountCurrentTurn = 68,
  killedEnemiesByOwnerInThisLifeCurrentRound = 106,
  killedAlliesCount = -61,
  killedAllyMinionsCount = -62,
  ownerDeadFromRelatedEffect = -63,
  isOwnerProduceRelatedEffect = -64,
  removedEffectsCount = -65,
  isCritical = -66,
  isSkillShown = -67,
  isTargetUnderDefenceReduction = 69,
  statusEffectIsApplied = -68,
  deadAlliesCount = 70,
  aliveEnemiesCount = 71,
  deadEnemiesCount = 72,
  unappliedStatusEffectsTurnsLeft = -69,
  unappliedStatusEffectsCount = -70,
  unappliedStatusEffectsCountByCurrentSkill = -171,
  unappliedStatusEffectsProducedByOwnerCount = -71,
  canUniqueApplyForTarget = -172,
  canUniqueApplyForSkill = -173,
  canUniqueApplyForRound = 76,
  ownerHasStatusEffectFromRelation = -113,
  healIsCritical = -72,
  producerIsTarget = 113,
  producerIsDead = 114,
  relatedEffectIsAoe = -73,
  targetIsDead = 115,
  targetDeadFromRelatedEffect = -74,
  relationTargetIsAlly = 116,
  targetIsBoss = 117,
  relationTargetIsBoss = -81,
  relationProducerIsBoss = -82,
  relationTargetIsMinion = -83,
  isFirstRelatedEffectOnTarget = -84,
  damageOnTargetInRoundCount = 118,
  realtionSkillIsDefault = -85,
  targetHasNoSkillsOnCooldown = 119,
  effectProducerIsSkillProducer = -174,
  ownerIsSkillProducer = -175,
  isPvPBattle = 122,
  producerHitCounterShieldCount = 123,
  reletionEffectScalesByTargetHp = -86,
  relationEffectScalesByContinuousDamageToInstantMul = -87,
  relationEffectScalesByDealtDamage = -94,
  debuffCountProducedByOwnerOnEnemies = 124,
  sameRelationProducerDamageCount = -88,
  ownersBuffOnTargetCount = 125,
  ownersBuffOnRelationTargetCount = -89,
  fromSnapshot_EnemyTeamDebuffsCountAfterBaseEffectStartProcessing = -137,
  ownersLevel = 126,
  ownersGrade = 127,
  ownersAscendLevel = 128,
  targetLevel = 180,
  relationChance = -96,
  relationRepeatCount = -97,
  relationProducerIsAlly = -98,
  relationProducerTypeId = -99,
  relationProducerBaseTypeId = -100,
  relationTargetTypeId = -101,
  relationTargetBaseTypeId = -102,
  targetTypeId = 129,
  targetBaseTypeId = 130,
  relationProcessedTargetCount = -103,
  damageDealtToRelationTargetByOwnerCount = -104,
  damageDealtToRelationTargetByOwnersDefaultSkillCount = -105,
  relatedStatusEffectTypeId = -106,
  relatedEffectId = -107,
  sameSkillCount = 145,
  revivedAlliesCount = -108,
  thereIsNoRelation = -109,
  heroKilledByProducer = -110,
  relationEffectIteration = -111,
  statusEffectResisted = -114,
  relationProducerId = -115,
  /**
   * - Who is the target of the skill applying a buff?
   */
  relationTargetId = -116,
  /**
   * - Who owns the skill applying a buff?
   */
  relationOwnerId = -117,
  producerId = 146,
  targetId = 147,
  ownerId = 148,
  AllyTeamMembersCount = 77,
  EnemyTeamMembersCount = 78,
  producerElement = 79,
  ownerElement = 80,
  targetElement = 81,
  relationTargetElement = -77,
  relationProducerElement = -78,
  magicElement = 82,
  spiritElement = 83,
  forceElement = 84,
  voidElement = 85,
  aliveAlliesCount = 86,
  hitsOnBossBySkillCurrentRoundCount = 88,
  effectProcessingInCurrentSkillContext = -176,
  relationChanceProcessed = -79,
  canUniqueApplyForTargetCurrentTurn = 90,
  canUniqueApplyForRelationTargetCurrentTurn = -143,
  canUniqueProcessForRound = 91,
  canUniqueApplyForSkillByProducer = -177,
  canUniqueApplyForTargetCurrentRound = 109,
  targetIsAlreadyHasExtraTurn = 93,
  relationFullRepeatingCount = -91,
  targetIsDying = 149,
  relationTargetIsDying = -118,
  producerIsDying = 150,
  relationProducerIsDying = -119,
  ownerIsDying = 140,
  relationTargetIsDead = -133,
  unapplyCause = -120,
  heroCausedRelationUnapplyId = -121,
  heroCausedRelationUnapplyTypeId = -122,
  relationKindId = -123,
  relationTypeId = -124,
  skillIdThatAppliedRelation = -125,
  skillIndexThatAppliedRelation = -126,
  effectThatCausedUnapplyKindId = -127,
  allyTeamId = 151,
  enemyTeamId = 152,
  killedEnemiesCountCurrentTurnByWholeTeam = 153,
  targetFactionId = 154,
  producerFactionId = 155,
  relationTargetFactionId = -156,
  relationProducerFactionId = -157,
  alliesWithoutBuffsCount = 95,
  enemiesWithoutBuffsCount = 96,
  alliesWithoutDebuffsCount = 97,
  enemiesWithoutDebuffsCount = 98,
  hitType = -75,
  anyBuffWasStolenFromTargetBySkill = -178,
  anyBuffWasRemovedFromTargetBySkill = -179,
  relatedEffectsBaseDestroyHp = -76,
  relatedEffectsCurrentDestroyHp = -95,
  relationDamageIgnoresUnkillable = -129,
  relationDamageIgnoresShield = -130,
  relationDamageIgnoresBlockDamage = -131,
  AppliedBuffsCountEnemyTeamCurrentTurn = 101,
  AppliedBuffsCountAllyTeamCurrentTurn = 102,
  AppliedDebuffsCountEnemyTeamCurrentTurn = 103,
  AppliedDebuffsCountAllyTeamCurrentTurn = 104,
  relationEffectHitsOnTargetCount = -132,
  LostHealthPercentsSinceLastProcessingOfEffect = 158,
  lostHealthPercentsFromDamageCurrentTurn = 163,
  lethalEffectOwnerId = 107,
  lethalEffectTypeId = 108,
  maxBuffCountInEnemyTeam = 110,
  currentIteration = 111,
  fromSnapshot_targetBuffsCountBeforeEffectProcessing = 112,
  fromSnapshot_targetDebuffsCountBeforeEffectProcessing = 131,
  canUniqueApplyForTurn = 132,
  skillProducerBaseTypeId = -180,
  producersSkillAlreadyInQueue = 134,
  skillProducerId = -181,
  skillTargetId = -182,
  effectThatCausedSkillKindId = -183,
  skillCausedByEffect = -184,
  skillContainsDamageEffect = -185,
  thereIsBossInCurrentRound = 141,
  producersTurnCountCurrentRound = 142,
  increasedCooldownCountByRelatedEffect = -139,
  targetCooldownsSum = 159,
  relationTargetCooldownsSum = -138,
  skyWrathStackCount = 143,
  transferredEffectsCountByRelatedEffect = -141,
  damageAbsorbedByShield = -140,
  producerWasRevivedInCurrentRound = 144,
  appliedUnprotectedDebuffsCountOnProducerByEnemyCurrentTurn = 156,
  appliedDebuffsCountOnProducerByEnemyCurrentTurn = 157,
  relationTargetDecreasedHealthCurrentTurn = -142,
  relationTargetHasAnyBlockablePassiveSkill = -144,
  targetHasAnyBlockablePassiveSkill = 105,
  unappliedBuffsCountFromAllyTeamByRelation = -145,
  unappliedBuffsCountFromEnemyTeamByRelation = -146,
  fromTargetsWithSkillOnCDSelectWithMaxStamina = 160,
  increasedSkillsCooldownToMaxCount = -147,
  producerComboCounterOnHeroes = 161,
  producerComboCounterOnBosses = 162,
  relationSkillId = -148,
  voidAbyssStackCount = 165,
  totalIncreasedTurnsCountBySkill = -186,
  totalDecreasedTurnsCountBySkill = -187,
  phaseThatTriggeredRelation = -150,
  phaseThatTriggeredEffect = 169,
  MostInjuredAliveAlly = 170,
  relatedEffectWasRedirected = -151,
  RelationTargetCocoonValue = -152,
  ProducerCocoonValue = 172,
  TargetCocoonValue = 171,
  hydraHitCounterStackCount = 176,
  heroIdWithHungerCounter = 173,
  IsFirstTargetCanGrowHydraHead = 174,
  relatedSkillSource = -153,
  aliveHydraHeadsCount = 175,
  relationAppliesControlEffect = -154,
  isFirstDamageFromPoisonPlacedByProducerSincePreviousTurn = -155,
  heroCounter = 177,
  activeHeroId = 183,
  targetHasControlDebuff = 179,
  relatedEffectForceTicked = -158,
  hitsByTargetCurrentRoundCount = 181,
  hitsByProducerFromSkillCurrentTurnCount = 182,
  skillIsDefault = -188,
  targetStaminaReducingCountCurrentTurn = 178,
  skillContainsDamageEffectByTarget = -189,
  ownersDoubleAscendLevel = 185,
  canUniqueApplyForBaseEffect = -190,
  targetTookDamageFromProcessedSkill = -160,
  relationAppliedOnRelationTarget = -161,
  relationShieldValue = -158,
  relatedEffectCancelled = -162,
  enemiesTookNonLethalDamageFromProcessedSkill = -163,
  enemiesTookDamageFromProcessedSkill = -165,
  relationDamageDefenceModifier = -164,
  thereIsProcessedSkill = -90,
  removedControlDebuffCount = -165,
  didProducerDieThisTurn = 186,
  relationProducerDoubleAscendLevel = -166,
  relationTargetDoubleAscendLevel = -167,
  targetDoubleAscendLevel = 187,
  targetBuffCountExcludeAppliedOnRoundStarted = 188,
}

export type ExpressionVars = Partial<Record<ExpressionBuilderVariable, number>>;
