export enum ExpressionBuilderFunction { // TypeDefIndex: 10346
  AllyTeamContainsHero = 1,
  EnemyTeamContainsHero = 2,
  TargetHasEffectOfKind = 3,
  TargetHasEffectOfType = 4,
  TargetHasEffectOfKindPlacedByProducer = 18,
  TargetHasEffectOfTypePlacedByProducer = 20,
  OwnerHasEffectOfKind = 5,
  ProducerHasEffectOfKind = 6,
  ProducerHasEffectOfKindPlacedByProducer = 17,
  ProducerHasEffectOfTypePlacedByProducer = 19,
  ActiveHeroHasEffectOfKind = 32,
  ProducerHadEffectOfKindBeforeDeath = 33,
  ProducerHadEffectOfTypeBeforeDeath = 39,
  RelationTargetHasEffectOfKind = -1,
  RelationTargetHasEffectOfType = -2,
  RelationTargetHasEffectOfKindPlacedByProducer = -11,
  RelationTargetHasEffectOfTypePlacedByProducer = -12,
  RelationProducerHasEffectOfKind = -16,
  RelationProducerHasEffectOfType = -17,
  RelationAppliesEffectOfKind = -3,
  RelationAppliesEffectOfType = -22,
  EffectsAppliedByProducerWholeBattleCountOfKind = 7,
  AllyTeamAppliedEffectsTotalCountOfKind = 8,
  EnemyTeamAppliedEffectsTotalCountOfKind = 9,
  AllyTeamAppliedEffectsTotalCountOfType = 10,
  EnemyTeamAppliedEffectsTotalCountOfType = 11,
  EffectsAppliedOnTargetCountOfKind = 12,
  EffectsAppliedOnRelationTargetCountOfKind = -4,
  OwnerHasLostEffectOfKind = -5,
  ProducerHasLostEffectOfKind = -6,
  TargetHasLostEffectOfKind = -7,
  RelationTargetHasLostEffectOfKind = -8,
  EnemyTeamHasLostEffectOfKind = -15,
  AllyTeamHeroCountWithId = 13,
  EnemyTeamHeroCountWithId = 14,
  HasShieldAppliedByCurrentSkillOnHeroWithId = 15,
  HitsCountCurrentTurnByRelationProducerToHeroWithId = -9,
  DebuffsCountCurrentTurnFromRelationProducerToHeroWithId = -10,
  EffectUsedByProducerCountWholeBattleWithId = 16,
  EffectUsedByProducerCountCurrentTurnWithId = 34,
  CritHitsCountWholeBattleFromEffectWithId = 43,
  EffectUsedByAllyTeamCountWholeBattleWithId = 50,
  LimitEffectUsages = 21,
  LimitEffectProcessings = 22,
  CountOfEffectsAppliedToAllAlliesThisTurnByKind = 23,
  CountOfEffectsAppliedToAllAlliesThisTurnByType = 24,
  StackedHpByEffectCurrentRound = 25,
  HeroTeamId = 26,
  HeroWithIdIsDead = 27,
  SkillUsedCountCurrentTurnByIndex = 28,
  SkillUsedCountOnTargetCurrentRoundByIndex = 38,
  DamageSkillUsedInRowCountToTargetWithId = -23,
  AllyTeamHeroCountWithFactionId = 30,
  EnemyTeamHeroCountWithFactionId = 31,
  ProducerHasEffectOfKindAppliedByCurrentSkillThisTurn = 35,
  IsInstantEffectByKindId = 36,
  ABS = 37,
  FLOOR = 55,
  AppliedEffectsCountDuringRelatedEffectProcessed = -13,
  NotLethalHitsCountFromEffectDuringRelatedEffectProcessed = -14,
  MostInjuredAllyWithBaseTypeId = 41,
  KilledMinionsCountBySkillCurrentTurn = 40,
  SummonedMinionsCountBySkillCurrentTurn = 42,
  fromSnapshot_EnemyTeamAppliedEffectsCountOfKindBeforeEffectProcessing = -18,
  SkillCooldownValueByIndex = 44,
  ShieldChangesWholeBattleBySkillWithId = 45,
  AllyTeamHeroCountOfElement = 46,
  EnemyTeamHeroCountOfElement = -46,
  RemovedStatusEffectsCountByCurrentSkillFromTarget = -24,
  fromSnapshot_baseRelationTargetEffectsCountOfKind = -19,
  TargetHasMaxStatValueInTeam = 48,
  GetHungerCounterValueOnHero = 49,
  IsEffectBlockedByStoneSkin = 51,
  IsEffectBlockedByPetrification = 52,
  TargetRankByStat = 53,
  UniqueAffinitiesCountInTeam = 54,
  TargetsEffectOfKindLifetime = 63,
  SkillUsedCountWholeBattle = 56,
  IsTargetTransformedIntoVariant = 60,
  IsRelationTargetTransformedIntoVariant = 58,
  CanTransformRelationProducerIntoVariant = 59,
  EffectStackCount = 57,
  RelationProducerRemovedFromHisAlliesEffectOfKind = -20,
  BuffsCountOnTeam = 61,
  DebuffsCountOnTeam = 62,
  RelatedTargetShieldValueByTypeId = -21,
  TotalDestroyedHPForTeam = 64,
}
